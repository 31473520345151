<template>
  <v-dialog v-model="dialog" persistent max-width="750">
    <v-card class="text-center justify-center align-center">
      <!-- <pre>{{ stages }}</pre> -->
      <!-- {{ vacancy ? vacancy.locale : "" }} -->
      <v-card-title class="text-center">
        {{ $t("Insert_stages_position", locale)
        }}<v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon small v-on="on">
              mdi-information-variant-circle-outline
            </v-icon>
          </template>
          <div
            style="width: 360px;"
            v-html="$t('the_stages_recruitment_process', locale)"
          ></div>
        </v-tooltip>
      </v-card-title>
      <v-container class="fill-heigt pt-0" fluid>
        <v-row class="justify-center">
          <v-col cols="12" md="12" v-if="!false">
            <v-select
              v-if="!$apollo.loading"
              v-model="vacancyId"
              :items="vacancies"
              item-text="title"
              item-value="id"
              :label="$t('Select_the_job_from_which_stages', locale)"
              dense
              outlined
              hide-details
              class="mx-3"
              @change="copyStages()"
            ></v-select>
            <v-row class="mx-3 mt-0" v-if="$apollo.loading"
              ><v-progress-linear
                indeterminate
                color="primary"
              ></v-progress-linear
            ></v-row>
          </v-col>
          <v-col cols="12" md="12" style="margin-top: -1.5%;" v-if="!false">
            <v-divider class="mx-3"></v-divider>
          </v-col>
          <v-col cols="12" md="12" style="margin-top: -1.5%;">
            <template v-for="(stage, index) in stages">
              <v-col cols="12" md="12" :key="`${index}stage`">
                <v-row>
                  <v-col cols="12" md="11">
                    <v-text-field
                      v-model="stage.name"
                      :label="language.add_name_stage"
                      outlined
                      dense
                      :readonly="index == 0"
                      maxlength="61"
                      :rules="[
                        () => !!stage.name || $t('required_field', locale),
                        () =>
                          (!!stage.name && stage.name.length <= 60) ||
                          $t('maximum_60_characters_long', locale),
                      ]"
                    ></v-text-field>

                    <v-textarea
                      v-model="stage.analyze"
                      :label="language.what_aspects_intend_analyze"
                      auto-grow
                      type="text"
                      dense
                      outlined
                      :readonly="index == 0"
                      v-show="index != 0"
                      rows="1"
                      :rules="requiredRules"
                    ></v-textarea>
                    <v-row v-show="index == 0" class="mx-0 mb-2">
                      <v-btn
                        x-small
                        outlined
                        fab
                        color="secondary"
                        @click="index === 0 ? addStage() : removeStage(index)"
                      >
                        <v-icon v-if="index === 0" small>mdi-plus</v-icon>
                        <v-icon v-else small>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="1" class="text-center mt-1">
                    <v-btn
                      x-small
                      outlined
                      fab
                      color="secondary"
                      @click="index === 0 ? addStage() : removeStage(index)"
                      v-show="index != 0"
                    >
                      <v-icon v-if="index === 0" small>mdi-plus</v-icon>
                      <v-icon v-else small>mdi-trash-can-outline</v-icon>
                    </v-btn>
                    <!-- <pre>{{ stage }}</pre> -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="index === 0"
                          fab
                          x-small
                          :color="
                            typeof stage.tests != 'undefined'
                              ? 'success'
                              : 'secondary'
                          "
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          @click="openSelectTest(index)"
                        >
                          <v-icon v-if="typeof stage.tests === 'undefined'"
                            >mdi-send</v-icon
                          >
                          <v-icon
                            v-if="typeof stage.tests != 'undefined'"
                            color="success"
                            >mdi-check</v-icon
                          >
                        </v-btn>
                      </template>
                      <div v-html="$t('associete_test', locale)"></div>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row style="margin-top: -3%;" v-show="index != 0">
                  <v-col cols="12" md="5">
                    <v-menu
                      ref="menu"
                      v-model="stage.menu"
                      :close-on-content-click="false"
                      :close-on-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <!-- {{ stage.menu }} -->
                        <v-text-field
                          v-model="stage.startDate"
                          :label="$t('start_date_time', locale)"
                          persistent-hint
                          prepend-inner-icon="event"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            stages[index].menu = true;
                            stage.menu1 = false;
                            stageIndex = index;
                          "
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-card max-width="290" min-height="400">
                        <v-row class="mt-1">
                          <v-col>
                            <v-date-picker
                              v-model="stage.startDate"
                              no-title
                              :min="today"
                              :events="functionEvents"
                            ></v-date-picker>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row style="margin-top: -4%; margin-bottom: 0%">
                          <v-col>
                            <v-alert
                              color="primaryLight"
                              dense
                              type="info"
                              class="text-left black--text caption mb-0"
                              ><span style="font-size: 8pt">{{
                                $t("Select_the_date", locale)
                              }}</span></v-alert
                            ></v-col
                          >
                        </v-row>
                        <v-row dense>
                          <v-col md="6">
                            <v-text-field
                              :value="stage.startDate.substring(0, 10)"
                              readonly
                              dense
                              :placeholder="$t('date', locale)"
                              solo
                              class="black--text caption"
                            ></v-text-field>
                          </v-col>
                          <v-col md="6">
                            <v-text-field
                              class="black--text caption"
                              solo
                              dense
                              :placeholder="$t('enter_the_time', locale)"
                              v-mask="'##:##'"
                              v-model="stage.startTime"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row dense class="mx-1 mb-3">
                          <v-spacer></v-spacer>

                          <v-btn
                            small
                            tile
                            depressed
                            outlined
                            color="primaryLight"
                            class="text-body-2 mr-2 mb-2"
                            @click="stage.menu = !stage.menu"
                          >
                            <span class="black--text text-capitalize">
                              {{ $t("cancel", locale) }}</span
                            >
                          </v-btn>
                          <v-btn
                            small
                            tile
                            depressed
                            color="primaryLight"
                            class="text-body-2 mb-2"
                            @click="
                              saveStarDate(
                                stage.startDate,
                                stage.startTime,
                                index
                              )
                            "
                          >
                            <span class="black--text text-capitalize">
                              {{ $t("save", locale) }}</span
                            >
                          </v-btn>
                        </v-row>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="1"></v-col>
                  <v-col cols="12" md="5">
                    <v-menu
                      ref="menu1"
                      v-model="stage.menu1"
                      :close-on-content-click="false"
                      :close-on-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="stage.endDate"
                          :label="$t('end_date_time', locale)"
                          persistent-hint
                          prepend-inner-icon="event"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            stage.menu = false;
                            stage.menu1 = true;
                            stageIndex = index;
                          "
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-card max-width="290" min-height="400">
                        <v-row class="mt-1">
                          <v-col>
                            <v-date-picker
                              v-model="stage.endDate"
                              no-title
                              :min="stage.startDate"
                              :events="functionEvents"
                            ></v-date-picker>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row style="margin-top: -4%; margin-bottom: 0%">
                          <v-col>
                            <v-alert
                              color="primaryLight"
                              dense
                              type="info"
                              class="text-left black--text caption mb-0"
                              ><span style="font-size: 8pt">{{
                                $t("Select_the_date", locale)
                              }}</span></v-alert
                            ></v-col
                          >
                        </v-row>
                        <v-row dense>
                          <v-col md="6">
                            <v-text-field
                              :value="stage.endDate.substring(0, 10)"
                              readonly
                              dense
                              solo
                              :placeholder="$t('date', locale)"
                              class="black--text caption"
                            ></v-text-field>
                          </v-col>
                          <v-col md="6">
                            <v-text-field
                              class="black--text caption"
                              solo
                              dense
                              :placeholder="$t('enter_the_time', locale)"
                              v-mask="'##:##'"
                              v-model="stage.endTime"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row dense class="mx-1 mb-3">
                          <v-spacer></v-spacer>
                          <v-btn
                            small
                            tile
                            depressed
                            outlined
                            color="primaryLight"
                            class="text-body-2 mr-2 mb-2"
                            @click="stage.menu1 = false"
                          >
                            <span class="black--text text-capitalize">
                              {{ $t("cancel", locale) }}</span
                            >
                          </v-btn>
                          <v-btn
                            small
                            tile
                            depressed
                            color="primaryLight"
                            class="text-body-2 mb-2"
                            @click="
                              saveEndDate(stage.endDate, stage.endTime, index)
                            "
                          >
                            <span class="black--text text-capitalize">
                              {{ $t("save", locale) }}</span
                            >
                          </v-btn>
                        </v-row>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="11" style="margin-top: -3%;">
                    <v-combobox
                      v-model="stage.participants"
                      :items="users"
                      :label="$t('participants', locale)"
                      multiple
                      item-text="name"
                      item-value="id"
                      chips
                      outlined
                      dense
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          :key="JSON.stringify(data.item.id)"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          :disabled="data.disabled"
                          @click:close="data.parent.selectItem(data.item.id)"
                        >
                          <v-avatar
                            class="accent white--text"
                            left
                            v-if="data.item.photo"
                          >
                            <v-img
                              :src="
                                data.item.photo
                                  ? `${apiUrl}/images/user/${data.item.photo}`
                                  : `/avatar.png`
                              "
                          /></v-avatar>

                          <v-avatar
                            v-else
                            class="accent white--text"
                            left
                            v-text="data.item.name.slice(0, 1).toUpperCase()"
                          ></v-avatar>

                          {{ data.item.name }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row
                  style="margin-top: -2%;"
                  class="mb-1"
                  v-show="stages.length - 1 != index"
                >
                  <v-divider></v-divider>
                </v-row>
              </v-col>
            </template>
          </v-col>
          <v-col cols="12" md="12" class="text-center">
            <v-btn text small color="primary" @click="$emit('close')">
              {{ $t("skip", locale) }}
            </v-btn>
            <v-btn small color="primary" @click="continueToPub()">
              {{ $t("save", locale) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <select-associate-test
        :dialog="dialogSelectTest"
        :SelectTestIndex="SelectTestIndex"
        @continue="continueSelectTest"
        @close="closeSelectTest()"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { API_URL } from "@/api";
import { GET_USERS_ENTITY_QUERY } from "../graphql/Query.resolver";
import { GET_VACANCIES_ENTITY_QUERY } from "../graphql/Query.resolver";
import SelectAssociateTest from "./SelectAssociateTest.vue";
export default {
  name: "StageDialog",
  components: {
    SelectAssociateTest,
  },
  props: {
    dialog: Boolean,
    language: Object,
    vacancyStages: Array,
    locale: String,
  },
  apollo: {
    users: {
      query: GET_USERS_ENTITY_QUERY,
      fetchPolicy: "no-cache",
    },
    vacancies: {
      query: GET_VACANCIES_ENTITY_QUERY,
      variables() {
        return { private: this.available, page: 1, pageSize: 10 };
      },

      fetchPolicy: "no-cache",
    },
  },
  data: () => ({
    vacancyId: "",
    menu: false,
    menu1: false,
    dialogSelectTest: false,
    SelectTestIndex: -1,
    apiUrl: API_URL,
    select: [],
    items: ["Programming", "Design", "Vue", "Vuetify"],
    vacancies: [],
    available: false,
    stages: [
      {
        menu: false,
        menu1: false,
        name: "",
        analyze: "",
        startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        startTime: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        endDate: "10:00",
        endTime: "10:00",
        participants: [],
        tests: [],
      },
    ],
  }),
  computed: {
    requiredRules() {
      return [(v) => !!v || this.$t("required_field", this.locale)];
    },
    today() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    calendarDates() {
      let calendar = [];
      if (this.vacancies) {
        this.vacancies.map((v) => {
          v.stages.map((s) => {
            if (s.startDate && s.endDate) {
              calendar.push({
                start: s.startDate.substring(0, 10),
                end: s.endDate.substring(0, 10),
              });
            }
          });
        });
      }
      return calendar;
    },
  },
  watch: {
    dialog() {
      this.stages = [...this.vacancyStages];
      this.stages[0].name = this.$t("default_stage_name", this.locale);
      this.stages[0].analyze = this.$t("default_stage_name", this.locale);
      // this.stages[0].tests = [];
      this.saveStarDate(this.stages[0].startDate, "10:00", 0);
      this.saveEndDate(this.stages[0].endDate, "10:00", 0);
    },
  },
  methods: {
    reset() {
      let tests = this.stages[0].tests;
      this.stages = [
        {
          menu: false,
          menu1: false,
          name: "",
          analyze: "",
          startDate: new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10),
          startTime: new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10),
          endDate: "10:00",
          endTime: "10:00",
          participants: [],
          // tests: [],
        },
      ];
      this.stages[0].name = this.$t("default_stage_name", this.locale);
      this.stages[0].analyze = this.$t("default_stage_name", this.locale);
      this.stages[0].tests = tests;
      this.saveStarDate(this.stages[0].startDate, "10:00", 0);
      this.saveEndDate(this.stages[0].endDate, "10:00", 0);
    },
    copyStages() {
      this.reset();
      let vacancy = this.vacancies.find((v) => v.id === this.vacancyId);
      console.log(vacancy.locale);
      if (
        vacancy.stages[0].name !== this.$t("default_stage_name", vacancy.locale)
      )
        this.stages.push(...vacancy.stages);

      if (this.stages.length) {
        this.stages = this.stages.map((stage) => {
          console.log(stage);
          delete stage.id;
          if (stage.title !== null || stage.title !== "") {
            return {
              ...stage,
              menu: false,
              menu1: false,
              startDate: "",
              startTime: "",
              endDate: "",
              endTime: "",
            };
          } else {
            return {
              ...stage,
              menu: false,
              menu1: false,
            };
          }
        });
      }
    },
    functionEvents(date) {
      var arrayColor = [];
      this.calendarDates.forEach((element) => {
        if (element.start) {
          if (date === element.start) {
            // if (arrayColor.length <= 2) {
            arrayColor.push("secondary");
            // }
          }
        }
      });
      return arrayColor;
    },
    continueToPub() {
      console.log(this.stages);
      this.$emit("continue", this.stages);
    },
    continueSelectTest(tests, index) {
      console.log(index, tests);
      this.stages[index].tests = tests;
    },
    openSelectTest(index) {
      this.SelectTestIndex = index;
      this.dialogSelectTest = true;
    },
    closeSelectTest() {
      this.dialogSelectTest = false;
    },
    saveStarDate(date, time, index) {
      if (time) {
        this.stages[index].startDate =
          date.substring(0, 10) + ` ${this.$t("at_", this.locale)} ` + time;
        this.stages[index].menu = false;
        this.stages[index].menu1 = false;
        console.log("cliquei");
      }
    },
    saveEndDate(date, time, index) {
      if (time) {
        this.stages[index].endDate =
          date.substring(0, 10) + ` ${this.$t("at_", this.locale)} ` + time;
        this.stages[index].menu = false;
        this.stages[index].menu1 = false;
      }
    },
    addStage() {
      this.stages.push({
        menu: false,
        menu1: false,
        name: "",
        analyze: "",
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        participants: [],
        tests: [],
      });
    },
    removeStage(index) {
      this.stages.splice(index, 1);
    },
  },
};
</script>
